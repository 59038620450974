import { createStyles } from 'utils/css'
import theme from 'utils/theme'
import { ISizes } from 'utils/types'

import { IHeadingStyles } from './Heading.types'

const { headingSizes } = theme

export const fontRatio = (rem, size: keyof ISizes) =>
  Number(rem.split('rem')[0]) * (size === 'xl' || size === 'lg' ? 0.65 : 1)

export const root = ({
  alignment,
  color,
  margin,
  size,
  styles
}: IHeadingStyles) => {
  const mobileSize = fontRatio(headingSizes[size], size)
  return createStyles(
    ({
      alignments,
      colors,
      headingSizes,
      mediaQuery,
      spacing,
      treatments,
      ...theme
    }) => {
      const defaultStyles = {
        ...treatments.heading,
        color: colors[color],
        textAlign: alignments[alignment],
        fontSize: theme.size(mobileSize),
        margin: margin && spacing(margin),
        [mediaQuery('sm')]: {
          fontSize: headingSizes[size]
        }
      }
      return { ...defaultStyles, ...styles }
    }
  )
}
