import { css as createClass, keyframes as setKeyframe } from '@emotion/react'
import { ITheme } from 'utils/types'

import theme from '../theme'

type ICSSObject = any

type ICSS = (theme: ITheme) => ICSSObject

export const createKeyframe = (styles: ICSSObject) => setKeyframe(styles)

export const createStyles = (cssFunction: ICSS) => cssFunction(theme)

export const stylesToClasses = (styles: ICSSObject) =>
  Object.keys(styles).reduce(
    (classNames, styleKey) => ({
      ...classNames,
      [styleKey]: createClass({ ...styles[styleKey], label: styleKey })
    }),
    {}
  )

export const addKeyframes = (keyframes: ICSSObject) =>
  Object.keys(keyframes).reduce(
    (keyframeNames, keyframeKey) => ({
      ...keyframeNames,
      [keyframeKey]: createKeyframe(keyframes[keyframeKey])
    }),
    {}
  )
