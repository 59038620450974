import { createStyles } from 'utils/css'

import { ITextStyles } from './Text.types'

export const root = ({
  alignment,
  color,
  margin,
  size,
  styles,
  fontWeight
}: ITextStyles) =>
  createStyles(({ alignments, colors, fontSizes, spacing, treatments }) => {
    const defaultStyles = {
      ...treatments.body,
      color: colors[color] || color,
      fontSize: fontSizes[size],
      fontWeight: fontWeight ?? 'inherit',
      textAlign: alignments[alignment],
      margin: margin && spacing(margin)
    }

    return { ...defaultStyles, ...styles }
  })
